<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Entry -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
            <b-button
              variant="primary"
              :to="{ name: 'apps-transactions-bacc-ar-add'}"
              class="mr-2"
            >
              <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.chbacc.bacc.actions.create') }}
            </b-button>

            <b-button
              variant="primary"
              :to="{ name: 'apps-transactions-bacc-ap-add'}"
            >
              <feather-icon icon="PlusIcon" /> {{ $t('apps.transactions.chbacc.bacc.actions.receive') }}
            </b-button> 
        </b-col> 

        
      </b-row>
    </div>

    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="searchQuery"
      responsive
      primary-key="id"
      show-empty
      :busy="isBusy"
      :empty-text="$t('globalSingular.noMatching')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <template #cell(code)="{item}">
        <div v-if="item.type_inv === 'PI'">
              <b-link
                :to="{ name: 'apps-transactions-purchase-invoicebacc-prints', params: { id: item.id }}"
                class="font-weight-bolder"
              >
              <span class="font-weight-bolder d-block text-nowrap">
                {{ item.code }}
              </span>
        </b-link>
        </div>
        <div v-else>
          <b-link
              :to="{ name: 'apps-transactions-sales-invoicebacc-prints', params: { id: item.id }}"
              class="font-weight-bolder"
          >
            <span class="font-weight-bolder d-block text-nowrap">
              {{ item.code }}
            </span>
        </b-link>

        </div>
        
      </template>

      <!-- Column: Customer -->
      <template #cell(name_customer)="{ item }">
        <div v-if="(item.name_customer !== '') && (item.name_customer !== null) ">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.name_customer }}
        </span>
        </div>
        <div v-else>
            <span class="font-weight-bolder d-block text-nowrap">
            -
        </span>
        </div>
      </template>

      <!-- Column: Lab -->
      <template #cell(agent)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
          {{ item.agent }}
        </span>
        <small class="font-italic">{{ item.remark }}</small>
      </template>

      <!-- Column: Amount -->
      <template #cell(amount)="{ item }">

        <div v-if="item.amount > 0 ">
          <span :class="['font-weight-bolder', 'd-block', 'text-nowrap',  'text-success']">
            {{
              formatCurrency(item.amount, false)
            }}
          </span>
        </div>
        <div v-else>
            <span :class="['font-weight-bolder', 'd-block', 'text-nowrap',  'text-danger' ]">
           ( {{
               formatCurrency(item.amount * -1, false) 
            }} )
            </span>
        </div>

      </template>

      <!-- Column: Outstanding Amount -->
      <template #cell(outstanding_amount)="{ item }">

      <div v-if="item.outstanding_amount > 0 ">
          <span :class="['font-weight-bolder', 'd-block', 'text-nowrap',  'text-success']">
            {{
              formatCurrency(item.outstanding_amount, false)
            }}
          </span>
        </div>
        <div v-else>
            <span :class="['font-weight-bolder', 'd-block', 'text-nowrap',  'text-danger' ]">
          (  {{formatCurrency(item.outstanding_amount, false) }} )
            </span>
        </div>

      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="{ item }">
        <div class="d-flex">
          <b-badge v-for="(tag, index) in item.tags" :key="index" pill variant="light-primary">{{ tag.name }}</b-badge>
        </div>
      </template>

      <template #cell(audit)="{ item }">
        <span class="font-weight-bolder d-block text-nowrap">
        {{ item.updated_process }}  By {{ item.getuser }}
        </span>
        <small class="font-italic">{{ new Date(item.updated_at).toDateString() }}</small>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('globalSingular.showing') }} {{ dataMeta.from }} {{ $t('globalSingular.to') }} {{ dataMeta.to }} {{ $t('globalSingular.of') }} {{ dataMeta.of }} {{ $t('globalSingular.entries') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BPagination,
  BSpinner,
  BBadge
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { formatCurrency } from '@/utils/formatter'
import useListTable from '@/comp-functions/useListTable'

// to access i18n
import useLocalization from '@/comp-functions/utils/useLocalization'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BPagination,
    BSpinner,
    BBadge
  },
  directives: {
    Ripple,
  },
  setup () {
    const { $t } = useLocalization()

    // Table Handlers
    const tableColumns = [
      { key: 'code', label: '#' },
      { key: 'date', label: $t('globalSingular.date'), sortable: true  },
      { key: 'office_name', label: $t('globalSingular.branch') },
      { key: 'name_customer', label: 'Customer', sortable: true, thClass: 'text-center', tdClass: 'text-center'  },
      // { key: 'invoice_no', label: 'Invoice No'},
      { key: 'agent', label: 'description'},
      { key: 'amount', label: $t('globalSingular.amount'), thClass: 'text-right', tdClass: 'text-right' },
      { key: 'outstanding_amount', label: 'Outstanding Amaount',thClass: 'text-right', tdClass: 'text-right'},
      { key: 'due_date', label: $t('due date') },
      { key: 'status', label: 'Status'},
      { key: 'tags', label: $t('globalSingular.tags') },
      { key: 'audit', label: $t('globalSingular.audit') }
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'code',
      'remark'
    ]

    return {
      formatCurrency,
      tableColumns,
      columnToBeFilter,
      ...useListTable({
        url: 'transaction/chbacc/invoice'
      })
    }
  }
}
</script>
